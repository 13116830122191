@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

:root {
    --principal-color: #1f2937;
}

.sticky {
    position: fixed;
    top: 0;
}

body {
    height: 100%;
    min-height: 100vh;
    position: relative;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

::-webkit-scrollbar {
    width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.content {
    overflow-x: hidden;
    line-height: 1.8;
}

.landing-header .special-contrast {
    filter: invert(1) grayscale(1) contrast(9) drop-shadow(.05em .05em black);
    mix-blend-mode: difference;
}

/* Parallax header Styles */

.parallax {
    background-image: url("\images\\landingphoto.jpeg");
    min-height: 600px;
    height: calc(100vh);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (max-device-width: 1366px) {
    .parallax {
        background-attachment: scroll;
    }
}

/* Footer Styles */

.footer-parent-class {
    height: 100px;
}

.footer-class {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    height: 25px;
    background-color: var(--principal-color);
    z-index: 1000;
}

.footer-class>a {
    color: rgb(80, 145, 189);
}

.footer-extra-class {
    position: absolute;
    background-color: var(--principal-color);
    color: white;
    bottom: 25px;
    padding: 10px 10%;
    font-size: 12px;
    width: 100%;
    display: flex;
}

.footer-icon-class>a {
    color: white;
    font-size: 21px;
    margin: 0px 15px;
}

/* Projects CSS */

.project-card:hover {
    transform: scale(1.03);
    z-index: 999;
}

.project-card .stick-to-bottom {
    position: absolute;
    bottom: 10px;
}

.projects-wrapper {
    position: relative;
}

.projects-wrapper:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 100px;
    bottom: 0;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.projects-parent {
    overflow-y: hidden;
}

.projects-parent.pvisible {
    transition: max-height 2s ease-in;
    max-height: 10000px;
}

.projects-parent.phidden {
    transition: max-height 1s cubic-bezier(0, 1, 0, 1);
    max-height: 450px;
}

/* Masonry layout */
.card-grid-container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    grid-auto-rows: min-content;
}

@media (max-width: 1566px) {
    .card-grid-container {
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    }
}

@media (max-width: 1266px) {
    .card-grid-container {
        grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    }
}

@media (max-width: 966px) {
    .card-grid-container {
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    }
}

.card-4 {
    grid-row: span 4;
}

.card-4 .project-card {
    height: 235px;
}

.card-5 {
    grid-row: span 5;
}

.card-5 .project-card {
    height: 300px;
}

.card-6 {
    grid-row: span 6;
}

.card-6 .project-card {
    height: 363px;
}

@media (max-width: 1266px) {

    .card-5 {
        grid-row: span 4;
    }

    .card-5 .project-card {
        height: 235px;
    }

    .card-6 {
        grid-row: span 5;
    }

    .card-6 .project-card {
        height: 300px;
    }
}

@media (max-width: 966px) {
    .card-4 {
        grid-row: span 4;
    }

    .card-4 .project-card {
        height: 200px;
    }

    .card-5 {
        grid-row: span 5;
    }

    .card-5 .project-card {
        height: 200px;
    }

    .card-6 {
        grid-row: span 6;
    }

    .card-6 .project-card {
        height: 22
        0px;
    }
}


.card-grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.work-in-progress {
    height: 100vh;
    width: 100vw;
    background-color: #434343;
}

.work-in-progress > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    height: 100px;
    color: white;
    text-transform: uppercase;
}